const site = ref();
const menu = ref();
const sources = ref();
const categories = ref();
const providers = ref();
const language = ref();
const loaded = ref(false);

export default async function () {
  const load = async () => {
    const [
      { data: _site },
      { data: _menu },
      { data: _sources },
      { data: _categories },
      { data: _providers },
      { data: _language },
    ] = await Promise.all([
      useAsyncData("site", () => queryContent(`site`).findOne()),
      useAsyncData("menu", () => queryContent(`menu`).findOne()),
      useAsyncData("s_all", () => queryContent(`source`).find()),
      useAsyncData(`c_all`, () => queryContent(`category`).find()),
      useAsyncData("p_all", () => queryContent("provider").find()),
      useAsyncData("language", () => queryContent("language").findOne()),
    ]);

    const sourcesResolved = _sources.value!.map((s) => ({
      ...s,
      _provider: _providers.value?.find((p) => p.slug === s.provider),
    }));

    const categoriesResolved = _categories.value!.map((c) => ({
      ...c,
      _source: c.sources.map((cs) =>
        sourcesResolved.find((s) => cs === s.slug)
      ),
    }));

    const menuResolved = _menu.value!.categories.map((mc) =>
      categoriesResolved?.find((c) => mc === c.slug)
    );

    site.value = _site.value;
    menu.value = menuResolved;
    sources.value = sourcesResolved;
    categories.value = categoriesResolved;
    providers.value = _providers.value;
    language.value = _language.value;
  };

  if (!loaded.value) {
    await load();
  }

  return {
    load,
    site,
    menu,
    sources,
    categories,
    providers,
    language,
  };
}
