import { ComputedRef } from "vue";

const watchedItems = ref<Array<ComputedRef<boolean>>>([]);
const isLoaderVisible = computed(() => watchedItems.value.some((x) => x.value));

export default function () {
  return {
    attach: (watchedVariable: ComputedRef<boolean>) => {
      watchedItems.value.push(watchedVariable);
      onBeforeUnmount(() => {
        const index = watchedItems.value.indexOf(watchedVariable);
        if (index > -1) {
          watchedItems.value.splice(index, 1);
        }
      });
      // return {
      //   detach: () => {
      //     const index = watchedItems.value.indexOf(watchedVariable);
      //     if (index > -1) {
      //       watchedItems.value.splice(index, 1);
      //     }
      //   },
      // };
    },
    isLoaderVisible,
  };
}
