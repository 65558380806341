const modalContext = reactive({
  isOpen: false,
  content: null,
});

const modalRef = ref(null);

export default function () {
  return {
    modalContext,
    modalRef,
    open: (content) => {
      modalContext.isOpen = true;
      modalContext.content = content;
    },
    close: () => {
      (modalRef.value as any)?.close?.();
    },
  };
}
