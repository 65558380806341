const isClient = !process.server;

// Shared state object
const globalSettings = reactive(new Map());

export default function useLocalSetting(key, version, initial) {
  const setting = ref(initial);

  const load = () => {
    if (isClient) {
      // Check if the setting already exists in the global state
      if (globalSettings.has(key)) {
        return globalSettings.get(key);
      } else {
        // Load from localStorage if not in global state
        const settingString = localStorage.getItem(key);
        if (settingString) {
          const loaded = JSON.parse(settingString);
          if (loaded.version >= version) {
            globalSettings.set(key, loaded.data); // Store in global state
            return loaded.data;
          }
        }
      }
    }
    return initial;
  };

  const save = (val) => {
    if (isClient) {
      localStorage.setItem(key, JSON.stringify({ data: val, version }));
      globalSettings.set(key, val); // Update global state
    }
  };

  if (isClient) {
    setting.value = load();
    onMounted(() => {
      setting.value = load();

      // Watch the local setting instance
      watch(
        setting,
        (newValue) => {
          save(newValue);
        },
        { deep: true }
      );

      // Sync changes from other instances
      watch(
        () => globalSettings.get(key),
        (newValue) => {
          if (newValue !== setting.value) {
            setting.value = newValue;
          }
        },
        { deep: true }
      );
    });
  }

  return setting;
}
